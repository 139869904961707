import { DocSearchIcon, TableIcon } from "components/IconsNew";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import ButtonWord from "components/ui/ButtonWord";
import { WarningIcon } from "components/ui/Icons";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  /* background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%); */

  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  justify-content: start;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 20px;
  column-gap: 12px;

  width: 236px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  line-height: 1.5;
`;

const CardLogo = styled.img`
  height: 40px;
`;

const CardButton = styled(ButtonWord)`
  padding: 0 4px;
`;

const Td = styled.td`
  /* background-color: white; */
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 20px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }
`;

const LogoAndText = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 4px;
  align-items: center;
`;

const Th = styled.th`
  /* background-color: white; */
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: #fbfbfb;
  }
`;

const Select = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px;
  font-weight: 500;
`;

const ModelOptionImg = styled.img`
  height: 14px;
`;

const CARDS = [
  {
    title: "Boltzbit LLM",
    logoSrc: "/images/logo-coloured.png",
  },
  {
    title: "ChatGPT",
    logoSrc: "/images/logo-openai.png",
  },
  {
    title: "Gemini",
    logoSrc: "/images/logo-gemini.png",
  },
  {
    title: "Llama 3",
    logoSrc: "/images/logo-meta.png",
  },
];

const MODEL_OPTIONS = CARDS.map(card => card.title);
const STEP_TYPES = [
  {
    label: "Search Document",
    value: "search_doc",
    icon: <DocSearchIcon />,
  },
  {
    label: "Writing Table",
    value: "writing_table",
    icon: <TableIcon />,
  },
  {
    label: "Error Checking",
    value: "error_checking",
    icon: <WarningIcon />,
  },
  {
    label: "Summarization",
    value: "summarization",
    icon: <DocSearchIcon />,
  },
];

const ModelSettingsPage = () => {
  const [stepToModelMap, setStepToModelMap] = useState({});

  return (
    <LayoutApp>
      <Container>
        <Title>Available models</Title>
        <Cards>
          {CARDS.map((card, index) => (
            <Card key={index}>
              <CardLogo src={card.logoSrc} />
              <CardTitle>{card.title}</CardTitle>
              <div
                style={{
                  gridColumn: "span 2",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 10,
                }}
              >
                <CardButton isPrimary>Enable</CardButton>
                <CardButton disabled>Disable</CardButton>
              </div>
            </Card>
          ))}
        </Cards>
        <Gap height="20px" />
        <table>
          <thead>
            <tr>
              <Th>Chain of Thought category</Th>
              <Th>Model</Th>
            </tr>
          </thead>
          <tbody>
            {STEP_TYPES.map((step, index) => {
              const modelValue = stepToModelMap[step.value];
              const modelLogoSrc =
                CARDS.find(card => card.title === modelValue)?.logoSrc ||
                CARDS[0].logoSrc;

              return (
                <Tr key={index}>
                  <Td>
                    <LogoAndText>
                      {step?.icon}
                      {step?.label}
                    </LogoAndText>
                  </Td>
                  <Td>
                    <LogoAndText style={{ gap: 40, marginLeft: 14 }}>
                      <ModelOptionImg src={modelLogoSrc} />
                      <Select
                        value={stepToModelMap[step.value] || "Boltzbit LLM"}
                        onChange={e =>
                          setStepToModelMap({
                            ...stepToModelMap,
                            [step.value]: e.target.value,
                          })
                        }
                      >
                        {MODEL_OPTIONS.map((option, index) => (
                          <option key={index}>{option}</option>
                        ))}
                      </Select>
                    </LogoAndText>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    </LayoutApp>
  );
};

export default ModelSettingsPage;
