import FieldReferenceModal from "components/FieldReferenceModal";
import { EyeWithDocIcon, LinkWithStarIcon } from "components/IconsNew";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import ReferenceModalSpanWithContext from "components/ReferenceModalSpanWithContext";
import { PLOT_TYPES, getPlotComponent } from "components/plots";
import {
  CrossIcon,
  PencilIcon,
  PlusIcon,
  TickIcon,
  TrashIcon,
} from "components/ui/Icons";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-content: start;
  align-items: start;
  gap: 10px;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;
  max-height: 220px;
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 2px 0;
  width: 100%;
  font-weight: 500;

  min-width: 0;
  outline: none;
  border-radius: 4px;
  border: none;
  border: 1px solid #c0c0c0;
  font-family: "Montserrat", sans-serif;

  :disabled {
    border: none;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Td = styled.td`
  padding: 4px;
  text-align: left;
  :nth-child(1) {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding-left: 8px;
  }
  :last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding-right: 8px;
  }
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px 8px;
  padding-bottom: 16px;
  text-align: left;
  color: #b9b9b9;
`;

const Tr = styled.tr`
  ${props => props.disabled && "pointer-events: none; opacity: 0;"}
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  height: 12px;
  fill: #8d8d8d;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  height: 12px;
  fill: #8d8d8d;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledFieldReferenceModal = styled(FieldReferenceModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const InputAndRef = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    height: 10px;
  }
  :hover {
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
  }
`;

const StyledSelect = styled.select`
  font-family: "Montserrat", sans-serif;
  border: 1px solid #497ff3;
  border-radius: 2px;
  outline: none;
`;

const RecordFieldInputTable = ({
  value = null,
  onChangeValue = () => {},
  onChangePlotState = () => {},
  isEditMode = false,
  initialPlotState = {},
  tableColumns = [],
  groupColor = "#0191ff",
}) => {
  const { selectedRecordIdStr } = useParams();
  // const [editedValue, setEditedValue] = useState(value);

  const [plotState, setPlotState] = useState({
    plotType: "Table",
    xColumnName: tableColumns?.find(column => column?.type === "TEXT")?.name,
    yColumnNames: tableColumns
      ?.filter(column => column?.type === "NUMBER")
      ?.map(col => col?.name),
  });

  useEffect(() => {
    if (isEmpty(initialPlotState)) {
      return;
    }
    setPlotState(initialPlotState);
  }, [JSON.stringify(initialPlotState)]);

  // useEffect(() => {
  //   onChangePlotState(plotState);
  // }, [JSON.stringify(plotState)]);

  const addNewRow = () => {
    const newRow = {};
    tableColumns.forEach(column => {
      newRow[column.name] = { value: "" };
    });
    onChangeValue([...(value || []), newRow]);
  };

  const editRow = (rowIndex, columnName, newValue) => {
    const newEditedValue = value.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...row,
          [columnName]: { value: newValue },
        };
      }
      return row;
    });
    onChangeValue(newEditedValue);
  };

  let rightButtons = null;

  let plotContent = (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {tableColumns.map((column, index) => (
            <Th key={index}>{column?.name}</Th>
          ))}
          {isEditMode && <Th />}
        </tr>
      </thead>
      <tbody>
        {value?.map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {tableColumns.map((column, columnIndex) => {
              return (
                <Td
                  key={`${selectedRecordIdStr}-${column?.name}-${rowIndex}-${columnIndex}`}
                >
                  <InputAndRef>
                    <FieldInput
                      disabled={!isEditMode}
                      value={row?.[column?.name]?.value || ""}
                      onChange={e =>
                        editRow(rowIndex, column?.name, e.target.value)
                      }
                    />
                    <StyledFieldReferenceModal
                      isDisabled={!row?.[column.name]?.reference}
                      column={column}
                      field={row?.[column?.name]}
                      reference={row?.[column.name]?.reference}
                    >
                      <EyeWithDocIcon
                        style={{
                          fill: row?.[column.name]?.reference
                            ? groupColor
                            : "#ccc",
                          marginTop: 2,
                        }}
                      />
                    </StyledFieldReferenceModal>
                    <ReferenceDebugModal
                      reference={row?.[column.name]?.reference}
                    />
                  </InputAndRef>
                </Td>
              );
            })}
            {isEditMode && (
              <Td>
                <StyledTrashIcon
                  onClick={() =>
                    onChangeValue(value.filter((row, i) => i !== rowIndex))
                  }
                />
              </Td>
            )}
          </Tr>
        ))}
        <Tr disabled={!isEditMode}>
          <StyledPlusIcon style={{ margin: 4 }} onClick={addNewRow} />
        </Tr>
      </tbody>
    </table>
  );

  const { plotType } = plotState;
  const plotProps = {
    params: plotState,
    isEditing: isEditMode,
    onChangeParams: newParams => onChangePlotState(newParams),
    tableColumns,
    records: value || [],
  };
  if (plotType !== "Table") {
    plotContent = getPlotComponent(plotType, plotProps);
  }

  let leftButtons = <div style={{ height: 14, gridColumn: "span 3" }} />;
  if (isEditMode) {
    leftButtons = (
      <div
        style={{
          gridColumn: "span 3",
          display: "grid",
          gridTemplateColumns: "1fr auto",
        }}
      >
        <StyledSelect
          style={{ justifySelf: "start", marginBottom: 4 }}
          value={plotType}
          onChange={e =>
            onChangePlotState({ ...plotState, plotType: e.target.value })
          }
        >
          {PLOT_TYPES.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </StyledSelect>
      </div>
    );
  }

  return (
    <TableContainer
      style={{
        gridTemplateColumns: isEditMode ? "1fr auto auto" : "1fr auto",
      }}
    >
      {leftButtons}
      <div>{plotContent}</div>
    </TableContainer>
  );
};

export default RecordFieldInputTable;
