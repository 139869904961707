import { useState, useEffect } from "react";
import styled from "styled-components";

import { deleteDirectory, postDirectories } from "api/services/fileSystem";
import {
  ExcelIcon,
  FolderIcon,
  PdfIcon,
  PencilIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  TrashIcon,
  WordIcon,
} from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import { deleteExcelModelById } from "api/services/excelModelsService";
import { deleteWordDoc, postWordDoc } from "api/services/wordDocsService";
import { deleteSlideDoc } from "api/services/slideDocsService";
import ErrorMessageModal from "components/ErrorMessageModal";
import { CircularProgress } from "@material-ui/core";
import { deleteFileById } from "api/services/filesService";
import { last } from "lodash";
import PipelineNameModalTriggerNew from "components/PipelineNameModalTriggerNew";
import {
  deletePipelineConfig,
  getPipelineConfigs,
} from "api/backend/fileSystemEndpoints";
import { useNavigate } from "react-router-dom";
import CreateAppModal from "components/CreatAppModal";
import { FunnelIcon } from "components/IconsNew";

const Container = styled.div`
  position: relative;
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledTooltipClick = styled(TooltipClick)`
  position: absolute;
  right: 0;
  top: 4px;
  right: 4px;
`;

const ActionsTrigger = styled.div`
  opacity: 0;
  z-index: 101;
  height: 16px;
  border-radius: 2px;
  display: grid;
  align-items: center;
  padding: 0 4px;
  background-color: ${props => props.theme.color.closer0};
  cursor: pointer;
  transition: opacity 0.1s;

  :hover {
    background-color: #a0a0a0;
  }
`;

const DirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 4px 0;
  padding-right: 4px;
  gap: 8px;
  align-items: center;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;

  :hover {
    background-color: #000000;
    ${ActionsTrigger} {
      opacity: 1;
    }
  }
`;

const FolderItem = styled.div``;

const ItemName = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eaeaea;
  font-weight: 500;
  text-decoration: none;
`;

const DirItemName = styled.div`
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #eaeaea;
  font-weight: 500;
`;

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
  margin-left: 10px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
  margin-left: 10px;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
  margin-left: 10px;
`;

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 12px;
  margin-left: 20px;
  fill: white;
`;

const StyledFunnelIcon = styled(FunnelIcon)`
  /* width: 12px; */
  margin-left: 20px;
  fill: white;
  height: 14px;
`;

const StyledActionWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledActionTrashIcon = styled(TrashIcon)`
  height: 14px;
`;

const StyledActionFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledActionPencilIcon = styled(PencilIcon)`
  height: 14px;
`;

const StyledActionSigmaIcon = styled(SigmaIcon)`
  height: 14px;
`;

const Actions = styled.div``;

const ActionItem = styled.div`
  height: 30px;
  padding: 0 8px;
  padding-right: 50px;
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 8px;

  :hover {
    background-color: #d8d8d8;
  }
`;

const DirNameHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 1px 0;
  gap: 8px;
  align-items: center;
  background-color: ${props => props.theme.color.closer0};
  ${props => props.isSticky && "position: sticky; top: 0;"}
  justify-content: space-between;
  z-index: 101;

  cursor: pointer;
  :hover {
    background-color: #d8d8d8;
  }
`;

const Dot = styled.div`
  width: 2px;
  height: 2px;
  background-color: #696969;
  border-radius: 50%;
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const ThreeDots = () => (
  <DotsContainer>
    <Dot />
    <Dot />
    <Dot />
  </DotsContainer>
);

const StyledPlusIcon = styled(PlusIcon)`
  height: 12px;
  :hover {
    opacity: 0.5;
  }
`;

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
};

const getItemIcon = item => {
  if (item?.isTemplate) {
    return <StyledSigmaIcon />;
  }

  return TYPE_TO_ICON[item?.type];
};

const getItemUrl = item => {
  if (item?.isTemplate) {
    return `/trial-dashboards/${item?.id}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.id}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.id}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.id}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.id}`;
  }

  return "";
};

const DirectoryItemsListPipelinesApps = ({
  directoryPath = "/working-files",
  dirNameComponent = null,
  isInitiallyOpen = false,
  isHeaderSticky = false,
  parentNewItemIds = [],
  isDeleteActionHidden = false,
  onRemoveSelf = () => {},
  indentPx = 0,
  isIconDisabled = false,
  renderHeaderComponent = null,
}) => {
  const navigate = useNavigate();

  const [pipelineConfigs, setPipelineConfigs] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [itemIdBeingDeleted, setItemIdBeingDeleted] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [newItemIds, setNewItemIds] = useState([]);
  const [isShowingFolderActions, setIsShowingFolderActions] = useState(false);

  useEffect(() => {
    if (isOpen || parentNewItemIds?.length > 0) {
      doPopulatePipelineConfigs();
    }
  }, [isOpen, parentNewItemIds?.length]);

  const doPopulatePipelineConfigs = async () => {
    setIsLoadingItems(true);
    const { data } = await getPipelineConfigs();
    setPipelineConfigs(data || []);
    setIsLoadingItems(false);
  };

  const doDeleteItem = async item => {
    setItemIdBeingDeleted(item?.id);
    setItemIdBeingDeleted(null);

    const { error } = await deletePipelineConfig(item?.id);
    if (error) {
      setError(error);
      return;
    }

    setPipelineConfigs(
      pipelineConfigs.filter(dirItem => dirItem?.id !== item?.id)
    );
  };

  const headerTipContent = (
    <Actions>
      <ActionItem onClick={() => navigate(`/apps/new/settings`)}>
        <StyledActionSigmaIcon />
        New app
      </ActionItem>
      {/* <CreateAppModal
        trigger={
          <ActionItem>
            <StyledActionSigmaIcon />
            New app
          </ActionItem>
        }
      /> */}

      {!isDeleteActionHidden && (
        <>
          {/* <ActionItem>
            <StyledActionPencilIcon />
            Rename
          </ActionItem> */}

          <ActionItem
            onClick={() =>
              doDeleteItem({
                type: "DIRECTORY",
                path: directoryPath,
              })
            }
          >
            <StyledActionTrashIcon />
            Delete
          </ActionItem>
        </>
      )}
    </Actions>
  );

  const dirDisplayName = last(directoryPath.split("/"));
  let dirNameHeader = (
    <DirNameHeader
      onClick={e => setIsOpen(prev => !prev)}
      isSticky={isHeaderSticky}
      onMouseEnter={() => setIsShowingFolderActions(true)}
      onMouseLeave={() => setIsShowingFolderActions(false)}
    >
      {!isIconDisabled && <StyledFolderIcon />}
      {dirNameComponent || <DirItemName>{dirDisplayName}</DirItemName>}
      <StyledTooltipClick tipContent={headerTipContent}>
        <ActionsTrigger
          style={{
            opacity: isShowingFolderActions || isHeaderSticky ? 1 : 0,
          }}
        >
          {isHeaderSticky ? <StyledPlusIcon /> : <ThreeDots />}
        </ActionsTrigger>
      </StyledTooltipClick>
    </DirNameHeader>
  );

  if (renderHeaderComponent && typeof renderHeaderComponent === "function") {
    dirNameHeader = renderHeaderComponent({ tipContent: headerTipContent });
  }

  if (isLoadingItems && pipelineConfigs?.length === 0) {
    return (
      <Container>
        {dirNameHeader}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem>
            <div />
            <CircularProgress size={16} thickness={6} />
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  if (!isLoadingItems && pipelineConfigs?.length === 0) {
    return (
      <Container>
        {dirNameHeader}
        <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
          <DirectoryItem style={{ marginLeft: indentPx }}>
            <div style={{ width: "0px" }} />
            <div style={{ opacity: 0.2 }}>No items</div>
          </DirectoryItem>
        </ItemsContainer>
      </Container>
    );
  }

  return (
    <Container>
      {dirNameHeader}
      <ItemsContainer style={{ display: isOpen ? "block" : "none" }}>
        {pipelineConfigs?.map(item => {
          // if (item?.type === "DIRECTORY") {
          //   return (
          //     <FolderItem key={item?.id} style={{ marginLeft: indentPx }}>
          //       <PipelineConfigsList
          //         parentNewItemIds={newItemIds}
          //         directoryPath={item?.path}
          //         onRemoveSelf={() => doPopulatePipelineConfigs()}
          //         indentPx={indentPx + 8}
          //       />
          //     </FolderItem>
          //   );
          // }

          return (
            <DirectoryItem
              isDisabled={itemIdBeingDeleted === item?.id}
              key={item?.id}
              style={{ marginLeft: indentPx }}
              onClick={() => navigate(`/apps/${item?.id}/home`)}
            >
              <StyledFunnelIcon />
              <ItemName>{item?.name}</ItemName>
              <StyledTooltipClick
                tipContent={
                  <Actions>
                    {/* <ActionItem>
                      <StyledActionPencilIcon />
                      Rename
                    </ActionItem> */}
                    <ActionItem onClick={() => doDeleteItem(item)}>
                      <StyledActionTrashIcon />
                      Delete
                    </ActionItem>
                  </Actions>
                }
              >
                <ActionsTrigger>
                  <ThreeDots />
                </ActionsTrigger>
              </StyledTooltipClick>
            </DirectoryItem>
          );
        })}
        <ErrorMessageModal
          message={error?.message}
          onDismiss={() => setError(null)}
        />
      </ItemsContainer>
    </Container>
  );
};

export default DirectoryItemsListPipelinesApps;
