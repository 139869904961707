import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const Popover = styled.div`
  position: fixed;
  z-index: 100;
  background-color: ${props => props.theme.color.furthest};
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 4px 0 ${props => props.theme.color.closer1_5};
  white-space: nowrap;
  width: 100%;
`;

const SearchablePopoverItems = ({
  placeholder = "",
  items = [],
  onSelectItem = id => {},
}) => {
  const ref = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const inputRect = ref.current?.getBoundingClientRect();

  return (
    <>
      <input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
        type="text"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder={placeholder}
      />
      <Popover
        style={{
          display: isFocused ? "block" : "none",
          top: inputRect?.bottom,
          left: inputRect?.left,
          width: inputRect?.width,
        }}
      >
        ...
      </Popover>
    </>
  );
};

export default SearchablePopoverItems;
