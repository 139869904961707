import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { cloneDeep, isEmpty, isEqual, last, range } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import { Done, FileCopy, KeyboardArrowDown, Replay } from "@material-ui/icons";

import {
  BoltzbitLogo,
  BoltzhubLogoInner,
  CrossIcon,
  DownloadIcon,
  PaperClipIcon,
  PdfIcon,
  PencilIcon,
  PlusIcon,
  RoundTickIcon,
  SendIcon,
} from "components/ui/Icons";
import NavHeader from "components/NavHeader";
import { Gap } from "components/Layout";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  getCubesFindByKey,
  getUserProfileByUserName,
  postDownloadExcelFileOfTable,
} from "api/services/projectService";
import { postAndStreamResponse } from "api/api-http-methods";
import RecordsPlot from "components/chat-plots/RecordsPlot";
import TooltipNew from "components/ui/TooltipNew";
import TextAreaExpanding from "components/TextAreaExpanding";
import { parseJson } from "utils/common";
import { postChatIngestToBoltzhb } from "api/services/chatService";
import LayoutNew from "components/LayoutNew";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import RegenActions from "components/RegenActions";
import { ArrowUpIcon, PersonBlankIcon } from "components/IconsNew";
import SourcesCards from "components/SourcesCards";
import ReferenceModalSpanWithContext, {
  getMetaId,
} from "components/ReferenceModalSpanWithContext";
import EmptyChatContent from "components/EmptyChatContent";
import useLoginFromCode from "hooks/useLoginFromCode";
import InputWithBottomSuggestionsAndSources from "components/InputWithBottomSuggestionsAndSources";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { IconButton } from "pages/word-docs/:wordDocId/new-shape";
import ButtonWord from "components/ui/ButtonWord";
import Tooltip from "components/ui/Tooltip";
import { GreyText } from "pages/chat";

export const getBlockTextSegments = block => {
  // const block = cloneDeep(inputBlock);
  // if (block?.text?.startsWith(" ")) {
  //   block.text = block.text.slice(1);
  // }
  const blockTextSegments = [];
  let currentSegment = {
    text: "",
    meta: null,
    url: "",
    bgColor: "transparent",
    isBold: false,
  };
  let i = 0;
  let isInBoldSegment = false;
  while (i < block?.text?.length) {
    let styleAtIndex = block?.styles?.find(
      style => i >= style?.start && i < style?.end
    );
    const metaAtIndex = styleAtIndex?.meta || null;
    const urlAtIndex = styleAtIndex?.url || null;

    if (block?.text?.[i] === "*" && block?.text?.[i + 1] === "*") {
      isInBoldSegment = !isInBoldSegment;
    }
    styleAtIndex = {
      ...(styleAtIndex || {}),
      isBold: isInBoldSegment,
    };
    const isBoldAtIndex = styleAtIndex?.isBold || false;

    if (
      !isEqual(metaAtIndex, currentSegment?.meta) ||
      !isEqual(urlAtIndex, currentSegment?.url) ||
      !isEqual(isBoldAtIndex, currentSegment?.isBold)
    ) {
      blockTextSegments.push(currentSegment);
      currentSegment = {
        text: "",
        url: urlAtIndex,
        meta: metaAtIndex,
        bgColor: styleAtIndex?.bgColor || "transparent",
        fontWeight: styleAtIndex?.fontWeight || "normal",
        isBold: isBoldAtIndex,
      };
    }

    currentSegment.text += block?.text?.[i];
    i++;
  }
  blockTextSegments.push(currentSegment);
  // if (block?.text?.startsWith("5")) {
  //   console.log({ blockTextSegments });
  // }

  return blockTextSegments;
};

export const getSegments = block => {
  // SIMPLIFIED STYLES RENDERING
  let segments = [{ text: "" }];
  let i = 0;
  let isBold = false;
  while (i <= block?.text?.length) {
    let stylesEndingAtIndex = block?.styles?.filter(style => style?.end === i);

    stylesEndingAtIndex?.forEach(style => {
      let isWholeBlockMeta = style?.end - style?.start === block?.text?.length;
      if (style?.metas) {
        let metas = style?.metas?.map(meta => ({
          id: getMetaId(meta, style?.value),
          value: style?.value,
          ...meta,
        }));
        segments.push({
          text: "",
          metas,
          isWholeBlockMeta,
        });
        segments.push({
          text: "",
        });
        return;
      }
    });

    if (block?.text?.[i] === "*" && block?.text?.[i + 1] === "*") {
      isBold = !isBold;
      segments.push({
        text: "",
        isBold,
      });
      i++;
      continue;
    }

    let charToAdd = block?.text?.[i] || "";
    if (charToAdd === "*") {
      charToAdd = "";
    }

    last(segments).isBold = isBold;
    last(segments).text += charToAdd;
    i++;
  }

  return segments;
};

// export const getSegments = block => {
//   return [{ text: block?.text }];
// };

export const renderSegments = (segments, block = {}, isGenerating = false) => {
  return segments?.map(segment => {
    if (segment?.metas || segment?.meta) {
      console.log({ segment });
      let tickStyle = {};
      return (
        <ReferenceModalSpanWithContext
          blocks={[block]}
          clickedMetaId={
            segment?.metas ? segment?.metas?.[0]?.id : segment?.meta?.id
          }
          metas={segment?.metas ? segment?.metas : [segment?.meta]}
        >
          <StyledRoundTickIcon style={tickStyle} height="12px" />
        </ReferenceModalSpanWithContext>
      );
    }

    return (
      <span
        style={{
          fontWeight: segment?.isBold ? 600 : 400,
        }}
      >
        {segment?.text}
      </span>
    );
  });
};

const Container = styled.div`
  position: relative;
  width: 800px;
  margin: 0 auto;
  display: grid;
  padding-top: 0px;
  height: calc(100vh - 40px);
  grid-template-rows: 1fr 73px;
  transition: width 0.2s;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledRecoInput = styled(InputWithBottomSuggestionsAndSources)`
  width: 800px;
  padding: 12px 32px;
  padding-right: 80px;
  padding-left: 48px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
  z-index: 1;
  max-height: 200px;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const TextBlocks = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const BlockText = styled.div`
  position: relative;
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 4px;
  padding: 0 10px;

  ${props => props.isIngested && `background-color: #0191ff11;`}
  ${props => props.isHighlighted && `background-color: #f1f1f1;`}

  ${props => props.isDisabled && `pointer-events: none;`}
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const StyledFileCopy = styled(FileCopy)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const Actions = styled.div`
  padding: 8px 0;
  padding-left: 35px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  /* height: 14px; */
  margin-left: 4px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledTickIcon = styled(Done)`
  transition: opacity 0.2s;
  fill: #848484;
  height: 14px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }

  ${props => props.disabled && `opacity: 0.5; pointer-events: none;`}
`;

const InputAndSendButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 4px;
  justify-items: start;
  padding-bottom: 4px;
  margin-left: -3px;
`;

const StyledInput = styled(TextAreaExpanding)`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: max-content;
  max-height: 200px;
  resize: none;
  line-height: 1.4;
  border: 1px solid #c7c7c7;
  :disabled {
    color: black;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const ProfilePicContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
  svg {
    margin-top: 3px;
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const TableConainer = styled.div`
  position: relative;
  padding-left: 0px;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 24px;
`;

const PulsingDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const StyledArrowDown = styled(KeyboardArrowDown)`
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  :hover {
    background-color: lightgrey;
  }
`;

const IndentedBlock = styled.div`
  position: relative;
  ${props => props?.isBold && `font-weight: 600;`}
  ${props =>
    props.hasListStyle &&
    `
  padding-left: 16px;
  ::before {
    position: absolute;
    left: 0px;
    top: -15px;
    font-size: 32px;
    content: "•";
  }
  `};
`;

const GreyRect = styled.div`
  height: 14px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eaeaea;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const StyledRoundTickIcon = styled(RoundTickIcon)`
  cursor: pointer;
  margin-left: 2px;
  fill: #00c85355;
  :hover {
    fill: #0191ff;
  }
`;

const DownloadIconContainer = styled.div`
  height: max-content;
  width: max-content;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  fill: white;
`;

const QueryNonEdit = styled.div`
  display: grid;
  gap: 4px;
  padding-bottom: 12px;
`;

const FadeRect = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  background: linear-gradient(transparent, white);
  z-index: 1;
  pointer-events: none;
  bottom: calc(20px + 53px);
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const isListBlock = block => {
  return block?.text?.startsWith("* ");
};

const isTitleBlock = block => {
  return block?.text?.startsWith("**") && block?.text?.endsWith("**");
};

const lightenColor = colorStr => {
  if (colorStr?.startsWith("#") && colorStr?.length === 9) {
    const coreColor = colorStr?.slice(0, 7);
    return `${coreColor}00`;
  }

  return colorStr;
};

const doDownloadTable = async (blocks, tableId) => {
  let tableColumns = [];
  let tablePreview = [];

  blocks
    ?.filter(b => b?.tableId === tableId)
    ?.forEach(block => {
      if (block?.rowIndex === 0) {
        tableColumns.push(block?.text);
        return;
      }

      if (block?.columnIndex === 0) {
        const columnName = tableColumns?.[0];
        tablePreview.push({
          [columnName]: {
            Value: block?.text,
            RawValue: block?.text,
          },
        });
        return;
      }

      const columnName = tableColumns?.[block?.columnIndex];
      try {
        last(tablePreview)[columnName] = {
          Value: block?.text,
          RawValue: block?.text,
        };
      } catch {}
    });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const HUB_BASE_URL = "https://hub.boltzbit.com";

const ChatDocPage = () => {
  const [searchParams] = useSearchParams();
  const { wordDocId } = useParams();

  const [userInput, setUserInput] = useState("");
  const [userSources, setUserSources] = useState([]);

  const [blocks, setBlocks] = useState([]);
  const [promptRecommendations, setPromptRecommendations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [avatarSrc, setAvatarSrc] = useState("");
  const [logoSrc, setLogoSrc] = useState("");
  const [canSeeBottom, setCanSeeBottom] = useState(true);
  // const [sources, setSources] = useSearchParamsState({
  //   paramName: "sources",
  //   initialValue: [],
  // });
  const [sources, setSources] = useState([]);

  const scrollerRef = useRef(null);

  const [pageResultsCache, setPageResultsCache] = useState({});

  const [highlightedQueryId, setHighlightedQueryId] = useState("");
  const [ingestingQueryId, setIngestingQueryId] = useState("");
  const [ingestedQueryIds, setIngestedQueryIds] = useState([]);
  const [editQueryId, setEditQueryId] = useState(null);
  const [editQueryText, setEditQueryText] = useState("");

  const [flowCube, setFlowCube] = useState(null);

  let chatQueriesUrl = "/bz-api/v1/ai/streamed-chat-queries";
  // let chatQueriesUrl =
  //   "/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed";
  // if (searchParams?.get("bz-api") === "true") {
  //   chatQueriesUrl = "/bz-api/v1/ai/streamed-chat-queries";
  // }
  if (searchParams?.get("pipelineId")) {
    chatQueriesUrl =
      "/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed";
  }

  useEffect(() => {
    doPopulateWordDocBlocks();
  }, [wordDocId]);

  useEffect(() => {
    doPopulateFlowDatasetId();
  }, []);

  const doPopulateWordDocBlocks = async () => {
    if (!wordDocId) {
      return;
    }
    const { data, error } = await getWordDoc(wordDocId);
    if (error) {
      return;
    }
    let newBlocks = data?.content?.blocks;
    setSources(data?.content?.sources || []);
    const newUserSources =
      last(newBlocks?.filter(b => b?.isQuery))?.userSources || [];
    setUserSources(newUserSources || []);

    if (!last(newBlocks)?.isQuery) {
      setBlocks(newBlocks);
      return;
    }

    setBlocks([
      ...newBlocks,
      { text: "...", isLoading: true, isThrowAway: true },
    ]);
    onPressEnterInCommandInput(
      last(newBlocks)?.text,
      newBlocks,
      newUserSources || []
    );
  };

  useEffect(() => {
    if (isGenerating || !blocks?.length) {
      return;
    }
    patchWordDoc(wordDocId, {}, { content: { blocks, sources } });
  }, [isGenerating]);

  const doPopulateFlowDatasetId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCube(data);
  };

  const doIngestDataPoint = async () => {
    setIngestingQueryId(highlightedQueryId);

    const ingestBody = {
      content: {
        blocks,
        sources,
      },
    };
    const { data: job } = await postChatIngestToBoltzhb(
      { queryId: highlightedQueryId },
      ingestBody
    );
    // await getDataIngestionJobUntilFinish(job?.dataIngestionJobId?.id);

    setIngestingQueryId("");
    setIngestedQueryIds([...ingestedQueryIds, highlightedQueryId]);
  };

  useEffect(() => {
    if (!scrollerRef.current) {
      return;
    }

    if (canSeeBottom) {
      scrollerRef.current.scrollTop = scrollerRef?.current?.scrollHeight;
    }

    const newCanSeeBottom =
      scrollerRef?.current?.scrollTop + scrollerRef?.current?.clientHeight >=
      scrollerRef?.current?.scrollHeight - 20;
    setCanSeeBottom(newCanSeeBottom);
  }, [canSeeBottom, scrollerRef.current]);

  useEffect(() => {
    const initialQuery = searchParams?.get("initialQuery");
    if (!initialQuery) {
      return;
    }

    const newBlocks = [
      {
        isQuery: true,
        text: initialQuery,
        styles: [
          {
            isSelection: true,
            start: initialQuery?.length,
            end: initialQuery?.length,
          },
        ],
      },
    ];
    setBlocks([
      ...newBlocks,
      { text: "...", isLoading: true, isThrowAway: true },
    ]);
    onPressEnterInCommandInput(initialQuery, newBlocks);
    // setBlocks(WORD_DOC_WITH_REFERENCE?.content?.blocks);
  }, [searchParams?.get("initialQuery")]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  useEffect(() => {
    setUserSources(sources);
  }, [JSON.stringify(sources)]);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setAvatarSrc(data?.image || "");
    setLogoSrc(data?.organisation || "");
  };

  const onPressEnterInCommandInput = async (
    query,
    payloadBlocks,
    payloadSources = [],
    actionIds = []
  ) => {
    setEditQueryId(null);
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    let sourcesToSend = userSources;
    if (searchParams?.get("pipelineId")) {
      sourcesToSend = [
        ...userSources,
        { type: "SIGMA_TEMPLATE", fileId: searchParams?.get("pipelineId") },
      ];
    }
    if (payloadSources?.length > 0) {
      sourcesToSend = payloadSources;
    }
    const payload = {
      searchBarQuery: null,
      slashQuery: query,
      cursor: { blockIndex, letterIndex },
      genContext: "word_query",
      blocks: payloadBlocks?.filter(block => !!block),
      sources: sourcesToSend,
      chainOfThought: searchParams?.get("chainOfThought") === "true",
    };
    if (actionIds?.length > 0) {
      payload.regenActionIds = actionIds;
    }

    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}${chatQueriesUrl}`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        setBlocks(data?.blocks || []);
        setPromptRecommendations(data?.promptRecommendations || []);
      },
    });

    setIsGenerating(false);
  };

  const doRerunBlock = async (index, actionIds = [], blocksToUse = []) => {
    if (blocksToUse?.length === 0) {
      blocksToUse = blocks;
    }

    const block = blocksToUse?.[index];
    const blocksWithoutSelection = removeSelectionStyle(blocksToUse);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(
      block?.text,
      blocksWithSelection,
      userSources,
      actionIds
    );
    let blocksWithLoading = cloneDeep(blocksToUse);
    blocksWithLoading[index + 1].isLoading = true;
    blocksWithLoading = blocksWithLoading.filter(
      oldBlock =>
        oldBlock?.isLoading ||
        oldBlock?.queryId !== block?.queryId ||
        oldBlock?.isQuery
    );
    setBlocks(blocksWithLoading);
  };

  const onKeyDown = e => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const blocksWithoutSelection = removeSelectionStyle(blocks);
      const payloadBlocks = [
        ...blocksWithoutSelection,
        {
          isQuery: true,
          text: userInput,
          userSources,
          styles: [
            {
              isSelection: true,
              start: userInput.length,
              end: userInput.length,
            },
          ],
        },
      ];
      setBlocks([
        ...payloadBlocks,
        { text: "...", isLoading: true, isThrowAway: true },
      ]);
      setUserInput("");
      onPressEnterInCommandInput(userInput, payloadBlocks);

      return;
    }
  };

  const stopGeneration = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGenerating(false);
    const newBlocks = cloneDeep(blocks);
    const updatedBlocks = newBlocks
      ?.filter(block => !block?.isThrowAway)
      .map(block => {
        block.isLoading = false;
        return block;
      });
    setBlocks(updatedBlocks);
  };

  const openSidebarType = parseJson(searchParams?.get("openSidebarType"));
  const isSideBarOpen = !!openSidebarType && !isEmpty(openSidebarType);
  const isMobile = window?.screen?.width < 1240;
  const isShowingRegenActions =
    searchParams?.get("showRegenActions") === "true";

  const inputElement = (
    <InputContainer>
      {isGenerating && (
        <div
          style={{
            position: "absolute",
            right: 16,
            top: 18,
            cursor: "pointer",
            zIndex: 2,
          }}
          onClick={stopGeneration}
        >
          <CrossIcon />
        </div>
      )}
      <StyledRecoInput
        id="input"
        placeholder={"Ask a question"}
        value={userInput}
        sources={sources}
        setSources={setSources}
        onChange={e => setUserInput(e.target.value)}
        onClickSuggestion={text => setUserInput(text)}
        onKeyDown={onKeyDown}
        userSources={userSources}
        setUserSources={setUserSources}
        externalSuggestions={promptRecommendations}
      />
      <GreyText>
        The model can make mistakes. Check important info.&nbsp;
        <a href="/terms" target="_blank">
          Terms of Services
        </a>
        &nbsp;and&nbsp;
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </GreyText>
    </InputContainer>
  );

  // if (!blocks?.length) {
  //   return (
  //     <LayoutNew bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);">
  //       <Container isSideBarOpen={isSideBarOpen}>
  //         <EmptyChatContent
  //           sources={sources}
  //           setSources={setSources}
  //           userSources={userSources}
  //           setUserSources={setUserSources}
  //           blocks={blocks}
  //           setBlocks={setBlocks}
  //           onPressEnterInCommandInput={onPressEnterInCommandInput}
  //         />
  //         {inputElement}
  //       </Container>
  //     </LayoutNew>
  //   );
  // }

  return (
    <LayoutNew
      bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);"
      topBarBgColor="#f3f5f7"
    >
      <Container isSideBarOpen={isSideBarOpen}>
        <TextBlocks
          onScroll={e => {
            const newCanSeeBottom =
              e.target.scrollTop + e.target.clientHeight >=
              e.target.scrollHeight - 20;
            setCanSeeBottom(newCanSeeBottom);
          }}
        >
          <Gap height="80px" />
          {blocks?.map((block, index) => {
            const prevBlock = blocks?.[index - 1];
            const nextBlock = blocks?.[index + 1];

            if (block?.records && block?.columns) {
              return (
                <BlockText
                  isHighlighted={highlightedQueryId === block?.queryId}
                  isIngested={ingestedQueryIds.includes(block?.queryId)}
                  isDisabled={ingestingQueryId === block?.queryId}
                >
                  {(block?.isQuery || prevBlock?.isQuery) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        marginTop: "0px",
                        padding: "0px 0",
                        paddingTop: "0px",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicContainer>
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="12px"
                        />
                      </ProfilePicContainer>
                      {/* <BoldSpan>Assistant</BoldSpan> */}
                    </div>
                  )}
                  <div style={{ paddingLeft: "35px" }}>
                    <div>{block?.text}</div>
                    <RecordsPlot
                      genCode={block?.genCode}
                      records={block?.records}
                      columns={block?.columns}
                    />
                  </div>
                  {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                    <Actions>
                      <TooltipNew tipText="Re-generate">
                        <StyledReplay
                          style={{
                            height: "16px",
                            width: "16px",
                          }}
                          onClick={() => {
                            const queryBlockIndex = blocks
                              ?.slice(0, index)
                              ?.findLastIndex(qBlock => qBlock?.isQuery);
                            doRerunBlock(queryBlockIndex);
                          }}
                        />
                      </TooltipNew>
                      {/* <TooltipNew
                        tipContent={
                          <div
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              marginTop: "8px",
                              padding: "2px",
                            }}
                          >
                            {ingestedQueryIds.includes(prevBlock?.queryId)
                              ? "Verified"
                              : "Verify"}
                          </div>
                        }
                      >
                        {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                          <StyledTickIcon
                            style={{
                              fill: "#0191ff",
                              height: "16px",
                              width: "16px",
                            }}
                            onClick={() => {
                              const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                              window.open(url, "_blank");
                            }}
                            onMouseOver={() =>
                              setHighlightedQueryId(prevBlock?.queryId)
                            }
                            onMouseOut={() => setHighlightedQueryId("")}
                          />
                        ) : (
                          <StyledTickIcon
                            style={{ height: "16px", width: "16px" }}
                            onClick={doIngestDataPoint}
                            onMouseOver={() =>
                              setHighlightedQueryId(prevBlock?.queryId)
                            }
                            onMouseOut={() => setHighlightedQueryId("")}
                          />
                        )}
                      </TooltipNew> */}
                    </Actions>
                  )}
                </BlockText>
              );
            }

            if (block?.isTableCell) {
              if (block?.rowIndex === 0 && block?.columnIndex === 0) {
                const numRows = block?.numberOfRows;
                const numCols = block?.numberOfColumns;
                const tableId = block?.tableId;

                return (
                  <div
                    style={{
                      paddingLeft: 24,
                      position: "relative",
                      display: "grid",
                      gridTemplateColumns: "20px 1fr",
                    }}
                  >
                    <DownloadIconContainer
                      style={{ paddingTop: 4 }}
                      onClick={() => doDownloadTable(blocks, tableId)}
                    >
                      <DownloadIcon />
                    </DownloadIconContainer>
                    <TableConainer>
                      <table>
                        <tbody>
                          {range(numRows).map(rowIndex => (
                            <tr key={`${tableId}-row-${rowIndex}`}>
                              {range(numCols).map(colIndex => {
                                const cellBlock = blocks?.find(
                                  b =>
                                    b?.tableId === tableId &&
                                    b?.rowIndex === rowIndex &&
                                    b?.columnIndex === colIndex
                                );

                                const segments = getSegments(cellBlock);

                                return (
                                  <Td
                                    key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                                  >
                                    {renderSegments(
                                      segments,
                                      cellBlock,
                                      isGenerating
                                    )}
                                    {/* {blockTextSegments?.map(segment => {
                                    if (segment?.meta) {
                                      return (
                                        <ReferenceModalSpan
                                          meta={segment?.meta}
                                          style={{
                                            backgroundColor: lightenColor(
                                              segment?.bgColor
                                            ),
                                            textDecoration: "underline",
                                            fontWeight: segment?.isBold
                                              ? 600
                                              : 400,
                                          }}
                                        >
                                          {segment?.text}
                                        </ReferenceModalSpan>
                                      );
                                    }
                                    return (
                                      <span
                                        style={{
                                          fontWeight: segment?.isBold
                                            ? 600
                                            : 400,
                                        }}
                                      >
                                        {segment?.text}
                                      </span>
                                    );
                                  })} */}
                                  </Td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableConainer>
                  </div>
                );
              }
              return null;
            }

            if (block?.imageBase64) {
              return (
                <BlockText
                  isDisabled={ingestingQueryId === block?.queryId}
                  style={{
                    backgroundColor: block?.isQuery ? "transparent" : "#f1f1f1",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      border: "1px solid #f1f1f1",
                    }}
                    src={`data:image/png;base64,${block?.imageBase64}`}
                    alt="plot"
                  />
                </BlockText>
              );
            }

            if (block?.isLoading) {
              return (
                <BlockText>
                  {(block?.isQuery || prevBlock?.isQuery) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        marginTop: "0px",
                        padding: "0px 0",
                        paddingTop: "0px",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicContainer>
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="12px"
                        />
                      </ProfilePicContainer>
                      {/* <BoldSpan>Assistant</BoldSpan> */}
                    </div>
                  )}
                  <div
                    style={{
                      paddingLeft: "35px",
                      marginBottom: "5px",
                      display: "grid",
                      gap: "5px",
                    }}
                  >
                    <GreyRect />
                    <GreyRect />
                    <GreyRect style={{ width: "50%" }} />
                  </div>
                </BlockText>
              );
            }

            let editButtons = (
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  marginTop: "4px",
                  marginBottom: "2px",
                  justifySelf: "end",
                }}
              >
                {editQueryId !== block?.queryId && (
                  <Tooltip
                    title="Edit"
                    isDisabled={editQueryId === block?.queryId}
                  >
                    <StyledBubbleSendButton
                      onClick={() => {
                        setEditQueryId(block?.queryId);
                        setEditQueryText(block?.text);
                      }}
                      disabled={editQueryId === block?.queryId}
                    >
                      <StyledPencilIcon />
                    </StyledBubbleSendButton>
                  </Tooltip>
                )}

                {editQueryId === block?.queryId && (
                  <Tooltip
                    title="Cancel"
                    isDisabled={editQueryId !== block?.queryId}
                  >
                    <StyledBubbleSendButton
                      onClick={() => {
                        setEditQueryId(null);
                        setEditQueryText("");
                      }}
                      disabled={editQueryId !== block?.queryId}
                    >
                      <CrossIcon style={{ fill: "white" }} />
                    </StyledBubbleSendButton>
                  </Tooltip>
                )}

                <Tooltip
                  title="Send"
                  isDisabled={editQueryId !== block?.queryId}
                >
                  <StyledBubbleSendButton
                    onClick={() => {
                      const newBlocks = cloneDeep(blocks);
                      newBlocks[index].text = editQueryText;
                      setBlocks(newBlocks);
                      setEditQueryText("");
                      setEditQueryId(null);
                      doRerunBlock(index, [], newBlocks);
                    }}
                    disabled={editQueryId !== block?.queryId}
                  >
                    <ArrowUpIcon height="14px" />
                  </StyledBubbleSendButton>
                </Tooltip>
              </div>
            );

            let blockEditableContent = (
              <QueryNonEdit>
                <div style={{ padding: "2px 0" }} key={block?.text}>
                  {block?.text}
                </div>
                {editButtons}
              </QueryNonEdit>
            );

            if (editQueryId === block?.queryId) {
              blockEditableContent = (
                <InputAndSendButton>
                  <StyledInput
                    value={editQueryText}
                    onChange={e => setEditQueryText(e.target.value)}
                  />
                  {editButtons}
                </InputAndSendButton>
              );
            }

            const segments = getSegments(block);

            return (
              <BlockText
                isHighlighted={
                  highlightedQueryId === block?.queryId && !block?.isQuery
                }
                isIngested={
                  ingestedQueryIds.includes(block?.queryId) && !block?.isQuery
                }
                isDisabled={ingestingQueryId === block?.queryId}
              >
                {(block?.isQuery || prevBlock?.isQuery) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      marginTop: "0px",
                      padding: "0px 0",
                      paddingTop: "0px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicContainer>
                      {block?.isQuery &&
                        (avatarSrc ? (
                          <Img src={avatarSrc} />
                        ) : (
                          <PersonBlankIcon
                            style={{ marginTop: "0px" }}
                            height="14px"
                          />
                        ))}
                      {!block?.isQuery && (
                        <BoltzhubLogoInner
                          style={{ fill: "url(#SvgGradientPurpleToBlue)" }}
                          height="12px"
                        />
                      )}
                    </ProfilePicContainer>
                  </div>
                )}
                <div style={{ paddingLeft: "35px" }}>
                  {block?.isQuery && block?.userSources?.length > 0 && (
                    <SourcesCards
                      sourceOptions={block?.userSources}
                      userSources={block?.userSources}
                      isEditingDisabled={true}
                      isInitiallyOpen
                      expandedHeight="auto"
                    />
                  )}
                  {block?.isQuery && blockEditableContent}
                  <IndentedBlock hasListStyle={isListBlock(block)}>
                    {
                      !block?.isQuery &&
                        !block?.regenActions?.length &&
                        renderSegments(segments, block, isGenerating)

                      // blockTextSegments?.map(segment => {
                      //   let segmentText = segment?.text;
                      //   segmentText = segment?.text?.replaceAll("**", "");
                      //   if (isListBlock(block)) {
                      //     segmentText = segment?.text?.replaceAll("* ", "");
                      //   }

                      //   if (segment?.url) {
                      //     return (
                      //       <a
                      //         href={segment?.url}
                      //         target="_blank"
                      //         rel="noreferrer"
                      //         style={{
                      //           fontWeight: segment?.isBold ? 600 : 400,
                      //         }}
                      //       >
                      //         {segmentText}
                      //       </a>
                      //     );
                      //   }

                      //   if (segment?.meta) {
                      //     const metas = [segment?.meta]?.map(meta => ({
                      //       ...(meta || {}),
                      //       id: getMetaId(meta, segmentText),
                      //     }));
                      //     return (
                      //       <>
                      //         {segmentText}
                      //         {/* <ReferenceModalSpan
                      //           meta={segment?.meta}
                      //           style={{
                      //             backgroundColor: lightenColor(
                      //               segment?.bgColor
                      //             ),
                      //             textDecoration: "underline",
                      //             fontWeight: segment?.isBold ? 600 : 400,
                      //           }}
                      //         >
                      //           ✓
                      //         </ReferenceModalSpan> */}
                      //         <ReferenceModalSpanWithContext
                      //           blocks={[block]}
                      //           metas={metas}
                      //         >
                      //           <StyledRoundTickIcon height="12px" />
                      //         </ReferenceModalSpanWithContext>
                      //       </>
                      //     );
                      //   }
                      //   return (
                      //     <span
                      //       style={{
                      //         fontWeight: segment?.isBold ? 600 : 400,
                      //       }}
                      //     >
                      //       {segmentText}
                      //     </span>
                      //   );
                      // })
                    }
                  </IndentedBlock>
                </div>
                {!block?.isQuery && (nextBlock?.isQuery || !nextBlock) && (
                  <Actions>
                    <TooltipNew tipText="Re-generate">
                      <StyledReplay
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                        onClick={() => {
                          const queryBlockIndex = blocks
                            ?.slice(0, index)
                            ?.findLastIndex(qBlock => qBlock?.isQuery);
                          doRerunBlock(queryBlockIndex);
                        }}
                      />
                    </TooltipNew>
                    {/* <TooltipNew
                      tipContent={
                        <div
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            marginTop: "8px",
                            padding: "2px",
                          }}
                        >
                          {ingestedQueryIds.includes(prevBlock?.queryId)
                            ? "Verified"
                            : "Verify"}
                        </div>
                      }
                    >
                      {ingestedQueryIds.includes(prevBlock?.queryId) ? (
                        <StyledTickIcon
                          style={{
                            fill: "#0191ff",
                            height: "16px",
                            width: "16px",
                          }}
                          onClick={() => {
                            const url = `${HUB_BASE_URL}/cube/${flowCube?.id}?tab=Dataset`;
                            window.open(url, "_blank");
                          }}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      ) : (
                        <StyledTickIcon
                          style={{ height: "16px", width: "16px" }}
                          onClick={doIngestDataPoint}
                          onMouseOver={() =>
                            setHighlightedQueryId(prevBlock?.queryId)
                          }
                          onMouseOut={() => setHighlightedQueryId("")}
                        />
                      )}
                    </TooltipNew> */}
                    <TooltipNew tipText="Copy">
                      <StyledFileCopy
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                        onClick={() => {
                          const queryBlocks = blocks?.filter(
                            b =>
                              b?.queryId === prevBlock?.queryId && !b?.isQuery
                          );
                          const queryStr = queryBlocks
                            ?.map(b => b?.text)
                            ?.join("\n");

                          navigator.clipboard.writeText(queryStr);
                        }}
                      />
                    </TooltipNew>
                  </Actions>
                )}
                {!isMobile &&
                  block?.regenActions?.length > 0 &&
                  isShowingRegenActions && (
                    <RegenActions
                      chevronText={block?.text}
                      actions={block?.regenActions}
                      doRerunBlock={doRerunBlock}
                      rerunIndex={index - 1}
                    />
                  )}
              </BlockText>
            );
          })}
          <Gap height="40px" />
          {!canSeeBottom && (
            <StyledArrowDown
              onClick={() => {
                scrollerRef.current.scrollTo({
                  top: scrollerRef?.current?.scrollHeight,
                  behavior: "smooth",
                });
              }}
            />
          )}
        </TextBlocks>

        {/* <InputArea>
          <LeftButtonsContainer>
            <DocSourcesModalTriggerNew
              alreadyAddedSources={sources}
              onAddSources={newSources => {
                setSources([...sources, ...newSources]);
                setUserSources([...userSources, ...newSources]);
                // patchWordDoc(
                //   wordDocId,
                //   {},
                //   { content: { sources: newSources, blocks } }
                // );
              }}
              trigger={
                <div
                  style={{
                    height: 18,
                    width: 18,
                    top: 0,
                    right: 4,
                    zIndex: 2,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gridColumn: "span 2",
                  }}
                >
                  <PlusInCircleIcon />
                </div>
              }
            />
          </LeftButtonsContainer>
          <StyledInputWithSuggestions
            areSuggestionsDisabled={!!searchParams?.get("pipelineId")}
            userSources={userSources}
            setUserSources={setUserSources}
            isSideBarOpen={isSideBarOpen}
            style={{ paddingLeft: "40px" }}
            disabled={isGenerating}
            value={userInput}
            onChange={e => {
              setUserInput(e.target.value);
            }}
            sources={sources}
            onClickSuggestion={text => {
              let newUserInput = text;
              setUserInput(newUserInput);
            }}
            placeholder="Ask a question"
            onKeyDown={e => {
              if (e.key === "Enter" && e.shiftKey) {
                return;
              }

              if (e.key === "Enter") {
                e.preventDefault();
                const blocksWithoutSelection = removeSelectionStyle(blocks);
                const payloadBlocks = [
                  ...blocksWithoutSelection,
                  {
                    isQuery: true,
                    text: userInput,
                    userSources,
                    styles: [
                      {
                        isSelection: true,
                        start: userInput.length,
                        end: userInput.length,
                      },
                    ],
                  },
                ];
                setBlocks([
                  ...payloadBlocks,
                  { text: "...", isLoading: true, isThrowAway: true },
                ]);
                setUserInput("");
                onPressEnterInCommandInput(userInput, payloadBlocks);

                return;
              }
            }}
          />
          <RightButtonsContainer>
            {isGenerating && (
              <StyledCrossIcon
                style={{ marginRight: "4px" }}
                onClick={() => {
                  abortController.abort();
                  setAbortController(new AbortController());
                  setIsGenerating(false);
                  const newBlocks = cloneDeep(blocks);
                  const updatedBlocks = newBlocks
                    ?.filter(block => !block?.isThrowAway)
                    .map(block => {
                      block.isLoading = false;
                      return block;
                    });
                  setBlocks(updatedBlocks);
                }}
              />
            )}
            {!isGenerating && (
              <>
                <SendButton
                  isActive
                  onClick={() => {
                    const blocksWithoutSelection = removeSelectionStyle(blocks);
                    const payloadBlocks = [
                      ...blocksWithoutSelection,
                      {
                        isQuery: true,
                        text: userInput,
                        userSources,
                        styles: [
                          {
                            isSelection: true,
                            start: userInput.length,
                            end: userInput.length,
                          },
                        ],
                      },
                    ];
                    setBlocks([
                      ...payloadBlocks,
                      { text: "...", isLoading: true, isThrowAway: true },
                    ]);
                    setUserInput("");
                    onPressEnterInCommandInput(userInput, payloadBlocks);
                  }}
                >
                  <ArrowUpIcon height="16px" />
                </SendButton>
              </>
            )}
          </RightButtonsContainer>
        </InputArea> */}
        {/* <SourceFilesList sources={sources} setSources={setSources} /> */}
        <FadeRect />
        {inputElement}
      </Container>
    </LayoutNew>
  );
};

export default ChatDocPage;
