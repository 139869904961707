import { DocSearchIcon, TableGridIcon } from "components/IconsNew";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TableContainer = styled.div`
  overflow: auto;

  table {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th:first-child {
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const NoRecordsDiv = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  height: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  color: ${props => props.theme.color.closer1};
  justify-content: center;
  font-weight: 600;
`;

const IconAndText = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0px;
  align-items: center;
`;

const PipelineRecordsTable = ({
  columns = [],
  pipelineConfig = {},
  sigmaRecords = [],
}) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <table style={{ width: "auto" }}>
        <thead>
          <tr>
            {columns?.map((column, i) => {
              const columnName = column?.name?.split("_")?.[1] || column?.name;
              return <Th key={column.name}>{columnName}</Th>;
            })}
          </tr>
        </thead>
        <tbody>
          {sigmaRecords.map(record => (
            <Tr key={`record-${record?.id}`}>
              {columns?.map((column, i) => {
                const onClick = () =>
                  navigate(`/apps/${pipelineConfig?.id}/records/${record?.id}`);

                const { value } = record?.fields?.[column.name] || {};
                let cellValue = value;
                if (!!cellValue && typeof cellValue === "object") {
                  cellValue = JSON.stringify(cellValue);
                }

                if (i === 0) {
                  return (
                    <Th onClick={onClick} key={column.name}>
                      <IconAndText>
                        <DocSearchIcon />
                        {cellValue}
                      </IconAndText>
                    </Th>
                  );
                }

                if (column?.type === "TABLE") {
                  return (
                    <Td key={column.name} onClick={onClick}>
                      <IconAndText style={{ color: "#7e7e7e" }}>
                        <TableGridIcon style={{ fill: "#fd9138" }} />
                        Go to table
                      </IconAndText>
                    </Td>
                  );
                }

                return (
                  <Td key={column.name} onClick={onClick}>
                    {cellValue}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </tbody>
      </table>
      {!sigmaRecords?.length && <NoRecordsDiv>No records</NoRecordsDiv>}
    </TableContainer>
  );
};

export default PipelineRecordsTable;
