import { ANIMATION_MS, ParamsEditor } from "components/BarChartParams";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColorFromString } from "utils/common";

const AreaChartParams = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const { xColumnName, yColumnNames } = params;

  const data = records?.map(record => {
    let dataPoint = {
      [xColumnName]: record?.[xColumnName]?.value,
    };
    yColumnNames?.forEach(yColumnName => {
      dataPoint = {
        ...dataPoint,
        [yColumnName]: record?.[yColumnName]?.value,
      };
    });

    return dataPoint;
  });

  return (
    <div>
      <ParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      />
      <ResponsiveContainer width="99%" height={180}>
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            interval={0}
            style={{ fontSize: "9px" }}
            dataKey={xColumnName}
          />
          <YAxis />
          {yColumnNames?.map(yColumnName => (
            <Area
              isAnimationActive
              animationDuration={ANIMATION_MS}
              key={`${yColumnName}-${JSON.stringify(data).slice(0, 100)}`}
              dataKey={yColumnName}
              stroke={getColorFromString(`c${yColumnName}`)}
              fill={getColorFromString(`c${yColumnName}`)}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
          <Tooltip contentStyle={{ backgroundColor: "white" }} cursor={false} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartParams;
