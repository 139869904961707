import { isNil } from "lodash";
import styled from "styled-components";

import { WarningIcon } from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  align-items: center;
  padding-right: 0px;
  min-width: 20px;
  width: 100%;

  svg {
    height: 12px;
    fill: #8d8d8d;
    cursor: pointer;
    :hover {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const FieldInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 0px 2px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldSelect = styled.select`
  background-color: transparent;
  color: black;
  padding: 0px 2px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const TipContainer = styled.div`
  background-color: black;
  color: white;
  padding: 4px;
`;

const DisplayValue = styled.div`
  background-color: transparent;
  color: black;
  padding: 2px 0;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const RecordFieldInput = ({
  value = null,
  onChange = () => {},
  type = "text",
  options = [],
  style = {},
  rawValue = "",
  displayValue = "",
  isEditMode = false,
}) => {
  if (isEditMode) {
    if (type === "select") {
      return (
        <Container style={style}>
          <FieldSelect style={style} value={value} onChange={onChange}>
            <option value=""></option>
            {options?.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FieldSelect>
        </Container>
      );
    }

    return (
      <Container style={style}>
        <FieldInput
          style={style}
          type={type}
          value={value}
          onChange={onChange}
        />
      </Container>
    );
  }

  if (rawValue && isNil(value)) {
    return (
      <Container style={style}>
        <TooltipNew
          tipContent={
            <TipContainer>
              <div>Could not parse:</div>
              <br />
              <div>"{rawValue}"</div>
              <br />
              <div>Please fill in manually</div>
            </TipContainer>
          }
          style={{
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: 5,
            alignItems: "center",
          }}
        >
          <DisplayValue>{rawValue}</DisplayValue>
          <WarningIcon style={{ fill: "#d4a600", cursor: "default" }} />
        </TooltipNew>
      </Container>
    );
  }

  return (
    <Container style={style}>
      <DisplayValue>{displayValue || value}</DisplayValue>
    </Container>
  );
};

export default RecordFieldInput;
