import {
  ApiIcon,
  ChatIcon,
  DocsIcon,
  FunnelIcon,
  PersonBlankIcon,
  SourcesIcon,
} from "components/IconsNew";
import NavHeader from "components/NavHeader";
import useSearchParamsState from "hooks/useSearchParamsState";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import DirectoryItemsList from "components/DirectoryItemsList";
import TooltipClick from "components/ui/TooltipClick";
import DirectoryItemsListDark from "components/DirectoryItemsListDark";
import { Gap } from "components/Layout";
import ButtonIcon from "components/ui/ButtonIcon";
import {
  BoltzbitLogo,
  BoltzhubLogoInner,
  ExcelIcon,
  HumanIcon,
  PlusIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";
import ProfileSidebar from "pages/profile-new/ProfileSidebar";
import ButtonWord from "components/ui/ButtonWord";
import {
  decodeStoredJwt,
  getLoggedInUserName,
} from "api/services/authenticationService";
import CreateWordModalTrigger from "components/CreateWordModalTrigger";
import { postWordDoc } from "api/services/wordDocsService";
import { postExcelModelDraft } from "api/services/excelModelsService";
import { uuidv4 } from "utils/common";
import { postSlideDoc } from "api/services/slideDocsService";
import MessageModal from "components/MessageModal";
import { useState } from "react";
import useResource from "hooks/useResource";
import DirectoryItemsListPipelinesApps from "components/DirectoryItemsListPipelinesApps";

const OuterContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 90px auto 1fr auto;
  background-color: #141718;
  height: ${props => props.minHeight};

  @media (max-width: 1240px) {
    grid-template-columns: 0px auto 1fr auto;
  }
`;

const TopBanner = styled.div`
  width: 100vw;
  height: 40px;
  z-index: 10000;
  top: 0;
  position: sticky;
  background: linear-gradient(176.39deg, #497ff3 0.89%, #38d0da 105.35%);
  display: grid;
  justify-content: center;
  align-items: center;
  color: white;
`;

const StyledNavHeader = styled(NavHeader)`
  top: 20px;
  right: 24px;
  background-color: transparent;
  box-shadow: none;
`;

const StyledTopRightButton = styled(ButtonWord)`
  position: fixed;
  top: 32px;
  right: 36px;
`;

const SideBar = styled.div`
  @media (max-width: 1240px) {
    display: none;
  }

  position: fixed;
  color: white;
  width: 90px;
  display: grid;
  grid-template-rows: repeat(6, auto) 1fr;
  gap: 10px;
  padding: 0 10px;
  box-shadow: 0px 24px 60px 0px #00000099;
  z-index: 102;
  height: ${props => props?.height};
  align-content: start;
  padding-bottom: 20px;
`;

const ContentCard = styled.div`
  position: relative;
  background: ${props => props.bgColor};
  margin: 20px;
  margin-left: 0;
  border-radius: 20px;
  grid-column: 3;
  overflow: auto;
`;

const SideItem = styled.div`
  display: grid;
  gap: 6px;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 58px;
  color: #848484;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `};

  svg {
    fill: ${props => (props.isActive ? "#497FF3" : "#ffffff")};
  }

  :hover {
    background-color: #717171;
    border-radius: 10px;

    background: linear-gradient(
      270deg,
      #323337 50%,
      rgba(70, 79, 111, 0.5) 100%
    );
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
      inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  }
`;

const SideItemActiveBg = styled(SideItem)`
  ${props =>
    props.isActive &&
    `
    background-color: #717171;
    border-radius: 10px;

    background: linear-gradient(270deg, rgba(73, 127, 243, 0.5) 50%, rgba(56, 208, 218, 0.4) 100%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
      inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  `}
`;

const ActiveApp = styled.div`
  width: 100%;
  height: 58px;
  display: grid;
  gap: 6px;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  color: #848484;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  border-top: 1px solid #515151;
  border-bottom: 1px solid #515151;

  svg {
    height: 26px;
    ${props => props.isActive && "fill: #497FF3 !important;"}
  }

  :hover {
    background-color: #717171;

    background: linear-gradient(
      270deg,
      #323337 50%,
      rgba(70, 79, 111, 0.5) 100%
    );
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
      inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  }

  ${props => props?.isDisabled && "pointer-events: none;"}
`;

const DirectoryHeaderContainer = styled.div`
  z-index: 102;
  padding: 10px 20px;
  padding-top: 20px;
  background-color: #232627;
  border-top-right-radius: 20px;
  position: sticky;
  top: 0;
`;

const SideActionBar = styled.div`
  grid-column: 2;
  transition: width 0.2s;
  overflow: auto;
  height: calc(100vh - 40px);
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #232627;
  margin-top: 20px;
  margin-right: 20px;
  z-index: 102;

  position: sticky;
  top: 20px;
`;

const SideActionBarRight = styled.div`
  grid-column: 4;
  transition: width 0.2s;
  overflow: hidden;
  height: calc(100vh - 40px);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-color: white;
  margin-top: 20px;
  z-index: 102;

  position: sticky;
  top: 20px;
`;

const SideTitle = styled.div`
  color: white;
  font-size: 22px;
  font-weight: 500;
  padding: 0px 0;
  padding-bottom: 16px;
`;

const BottomBorder = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #343839;
  left: 0;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  height: auto;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 36px;
  margin-top: 32px;
  margin-bottom: 18px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
`;

const StyledSelect = styled.select`
  color-scheme: dark;
  font-family: "Montserrat", sans-serif;
`;

const StyledLink = styled(Link)`
  justify-self: center;
`;

const StyledAnchor = styled.a`
  justify-self: center;
`;

const BtnText = styled.span``;

// hides fixed overflow elements
const BlackBar = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #141718;
  z-index: 1;
  left: 0;
`;

const WhiteBar = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1;
`;

const SIDE_NAV_ITEMS = [
  { icon: <ChatIcon />, text: "Chat", type: "docs", filterType: "CHAT_DOC" },
  {
    icon: <WordIcon style={{}} />,
    text: "Word",
    type: "word",
    filterType: "WORD_DOC",
    comingSoonMsg: "Word Docs coming soon",
  },
  {
    icon: <ExcelIcon style={{}} />,
    text: "Excel",
    type: "excel",
    filterType: "EXCEL_MODEL",
    comingSoonMsg: "Excel coming soon",
  },
  {
    icon: <SlidesIcon style={{ height: "20px" }} />,
    text: "PPT",
    type: "ppt",
    filterType: "SLIDE_DOC",
    comingSoonMsg: "PPT coming soon",
  },
  {
    icon: <FunnelIcon />,
    text: "Apps",
    type: "pipelines",
    comingSoonMsg: "Automation apps coming soon",
    // isDisabled: true,
  },
  {
    icon: <SourcesIcon />,
    text: "Sources",
    type: "sources",
  },
  // {
  //   icon: <ApiIcon />,
  //   text: "API",
  //   type: "api",
  //   comingSoonMsg: "API coming soon",
  // },
];

const doCreateWordDoc = async dirPath => {
  const { data, error } = await postWordDoc(
    {},
    {
      fileName: `Untitled Doc`,
      directoryPath: dirPath,
      content: { blocks: [{ text: "" }] },
    }
  );
  if (!error) {
    window.open(`/word-docs/${data?.id}/new-shape`, "_blank");
  }
};

const doCreateExcelModel = async () => {
  const { data: newModel, error } = await postExcelModelDraft({
    directoryPath: "/working-files",
  });
  if (!error) {
    window.open(`/excel-views/${newModel?.id}`, "_blank");
  }
};

const DOCS_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Chats</SideTitle>
        <Gap />
        <TooltipClick
          tipStyle={{ marginLeft: "-200px", width: "max-content" }}
          tipContent={tipContent}
        >
          <StyledButtonIcon isActive>
            <PlusIcon height="10px" />
            <BtnText>New</BtnText>
          </StyledButtonIcon>
        </TooltipClick>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat"]}
  />
);

const WORD_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Word Documents</SideTitle>
        <Gap />
        {/* <StyledButtonIcon
          isActive
          onClick={() => doCreateWordDoc("/working-files")}
        >
          <PlusIcon height="10px" />
          <BtnText>New</BtnText>
        </StyledButtonIcon> */}
        <CreateWordModalTrigger
          trigger={
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          }
        />
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat", "new_word_doc", "upload_word_doc"]}
  />
);

const EXCEL_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Excel Files</SideTitle>
        <Gap />
        <TooltipClick
          tipStyle={{ marginLeft: "-200px", width: "max-content" }}
          tipContent={tipContent}
        >
          <StyledButtonIcon isActive>
            <PlusIcon height="10px" />
            <BtnText>New</BtnText>
          </StyledButtonIcon>
        </TooltipClick>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_excel", "upload_excel"]}
  />
);

const PPT_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Presentations</SideTitle>
        <Gap />
        <TooltipClick
          tipStyle={{ marginLeft: "-200px", width: "max-content" }}
          tipContent={tipContent}
        >
          <StyledButtonIcon isActive>
            <PlusIcon height="10px" />
            <BtnText>New</BtnText>
          </StyledButtonIcon>
        </TooltipClick>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_ppt", "upload_ppt"]}
  />
);

const TYPE_TO_CONTENT = {
  word: WORD_SIDEBAR,
  docs: DOCS_SIDEBAR,
  excel: EXCEL_SIDEBAR,
  ppt: PPT_SIDEBAR,
  sources: (
    <DirectoryItemsList
      renderHeaderComponent={({ tipContent }) => (
        <DirectoryHeaderContainer>
          <SideTitle>Sources</SideTitle>
          <Gap />
          <TooltipClick
            tipStyle={{ marginLeft: "-200px", width: "max-content" }}
            tipContent={tipContent}
          >
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          </TooltipClick>
          <Gap />
        </DirectoryHeaderContainer>
      )}
      isInitiallyOpen
      isDeleteActionHidden
      directoryPath="/source-files"
      folderActions={[
        "upload_files",
        // "ingest_webpage",
        // "send_email",
        "upload_audio",
      ]}
    />
  ),
  // pipelines: (
  //   <DirectoryItemsListPipelines
  //     renderHeaderComponent={({ tipContent }) => (
  //       <DirectoryHeaderContainer>
  //         <SideTitle>Pipelines</SideTitle>
  //         <Gap />
  //         <TooltipClick
  //           tipStyle={{ marginLeft: "-200px", width: "max-content" }}
  //           tipContent={tipContent}
  //         >
  //           <StyledButtonIcon isActive>
  //             <PlusIcon height="10px" />
  //             <BtnText>New</BtnText>
  //           </StyledButtonIcon>
  //         </TooltipClick>
  //         <Gap />
  //       </DirectoryHeaderContainer>
  //     )}
  //     isInitiallyOpen
  //     isDeleteActionHidden
  //     directoryPath="/template-files"
  //     isIconDisabled
  //   />
  // ),
  pipelines: (
    <DirectoryItemsListPipelinesApps
      renderHeaderComponent={({ tipContent }) => (
        <DirectoryHeaderContainer>
          <SideTitle>Apps</SideTitle>
          <Gap />
          <TooltipClick
            tipStyle={{ marginLeft: "-200px", width: "max-content" }}
            tipContent={tipContent}
          >
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          </TooltipClick>
          <Gap />
        </DirectoryHeaderContainer>
      )}
      isInitiallyOpen
      isDeleteActionHidden
      directoryPath="/template-files"
      isIconDisabled
    />
  ),
  profile: (
    <DirectoryHeaderContainer>
      <SideTitle>Settings</SideTitle>
      <BottomBorder />
      <ProfileSidebar />
    </DirectoryHeaderContainer>
  ),
};

const ROUTES_TO_ACTIVE_APP = [
  {
    path: "/chat-docs",
    filterType: "CHAT_DOC",
    type: "docs",
    icon: <ChatIcon style={{ fill: "#ffffff" }} />,
  },
  {
    path: "/word-docs",
    type: "docs",
    filterType: "WORD_DOC",
    icon: <WordIcon />,
  },
  {
    path: "/slide-docs",
    type: "docs",
    filterType: "SLIDE_DOC",
    icon: <SlidesIcon style={{ fill: "#d4aa00" }} />,
  },
];

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
`;

const MessageText = styled.div`
  font-size: 20px;
  white-space: nowrap;
`;

const LayoutNew = ({
  className = "",
  style = {},
  children,
  isRightSideOpen = false,
  rightContent = null,
  bgColor = "white",
  isFullScreen = false,
  topBarBgColor = "transparent",
  isModelDropdownVisible = true,
}) => {
  const [searchParams] = useSearchParams();
  const [userProfile] = useResource({
    url: `/solutions/ocr/authentication-service/api/v1/user-profiles/${getLoggedInUserName()}`,
  });

  const location = useLocation();
  const [sidebar, setSidebar] = useSearchParamsState({
    paramName: "sidebar",
    initialValue: {},
  });

  const [messageModalContent, setMessageModalContent] = useState(null);

  const activeApp = ROUTES_TO_ACTIVE_APP?.find(app => {
    return location.pathname.includes(app.path);
  });

  const openSidebarType = sidebar?.type;

  const tokenObj = decodeStoredJwt();
  const isRestrictedMode = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");
  const isAdmin =
    tokenObj?.authorities?.includes("ROLE_ADMIN") ||
    tokenObj?.sub === "1ba5c9b6-c8a6-4e6e-acc5-3324ace073e0" || // ben
    tokenObj?.sub === "d1bb766b-9049-454a-9978-51fa9928335e"; // video editor
  const isTrialAdmin =
    tokenObj?.authorities?.includes("ROLE_USER") &&
    tokenObj?.sub === "trial-user";

  if (window?.screen?.width < 1240) {
    return <div>{children}</div>;
  }

  if (isFullScreen) {
    return (
      <Container style={{ gridTemplateColumns: "1fr" }}>
        <ContentCard
          bgColor={bgColor}
          style={{ gridColumn: 1, marginLeft: 20 }}
        >
          <BlackBar style={{ top: 0 }} />
          <StyledNavHeader isModelDropdownVisible />
          {children}
        </ContentCard>
      </Container>
    );
  }

  return (
    <OuterContainer>
      <Container className={className} style={style} minHeight="100%">
        <SideBar height="100vh">
          <StyledLink to="/">
            <StyledBoltzbitLogo />
          </StyledLink>

          {SIDE_NAV_ITEMS.map((item, index) => (
            <SideItem
              isDisabled={item?.isDisabled}
              isActive={
                sidebar?.type === item?.type &&
                sidebar?.filterType === item?.filterType
              }
              key={index}
              onClick={() => {
                if (item?.type === "api") {
                  window.open("/api-docs", "_blank");
                  return;
                }

                let newSidebar = {
                  type: item?.type,
                  filterType: item?.filterType,
                };
                if (
                  sidebar?.type === item?.type &&
                  sidebar?.filterType === item?.filterType
                ) {
                  newSidebar = {};
                }

                if (
                  (item?.comingSoonMsg && !isAdmin && !isTrialAdmin) ||
                  searchParams?.get("showComingSoon") === "true"
                ) {
                  setMessageModalContent(
                    <>
                      {item?.icon}
                      <MessageText>{item?.comingSoonMsg}</MessageText>
                    </>
                  );
                  return;
                }

                if (isRestrictedMode && item?.type === "docs") {
                  setMessageModalContent(
                    <>
                      {item?.icon}
                      <MessageText>
                        <a href="/signup">Sign up</a> to see chat history
                      </MessageText>
                    </>
                  );
                  return;
                }

                if (isRestrictedMode && item?.type === "sources") {
                  setMessageModalContent(
                    <>
                      {item?.icon}
                      <MessageText>
                        <a href="/signup">Sign up</a> to see uploaded files
                      </MessageText>
                    </>
                  );
                  return;
                }

                setSidebar(newSidebar);
              }}
            >
              {item.icon}
              {item.text}
            </SideItem>
          ))}
          <SideItemActiveBg
            isDisabled={isRestrictedMode}
            style={{ alignSelf: "end" }}
            isActive={sidebar?.type === "profile" && !sidebar?.filterType}
            onClick={() => {
              let newSidebar = { type: "profile" };
              if (sidebar?.type === "profile" && !sidebar?.filterType) {
                newSidebar = {};
              }

              setSidebar(newSidebar);
            }}
          >
            {userProfile?.image ? (
              <ProfileImg src={userProfile?.image} />
            ) : (
              <PersonBlankIcon />
            )}
          </SideItemActiveBg>
        </SideBar>
        <SideActionBar style={{ width: openSidebarType ? 240 : 0 }}>
          {openSidebarType === "profile" && TYPE_TO_CONTENT["profile"]}
          {openSidebarType === "chats" && TYPE_TO_CONTENT["chats"]}
          {openSidebarType === "docs" && TYPE_TO_CONTENT["docs"]}
          {openSidebarType === "excel" && TYPE_TO_CONTENT["excel"]}
          {openSidebarType === "ppt" && TYPE_TO_CONTENT["ppt"]}
          {openSidebarType === "word" && TYPE_TO_CONTENT["word"]}
          {openSidebarType === "sources" && TYPE_TO_CONTENT["sources"]}
          {openSidebarType === "pipelines" && TYPE_TO_CONTENT["pipelines"]}
        </SideActionBar>
        <BlackBar style={{ top: 0 }} />
        <ContentCard bgColor={bgColor}>
          <WhiteBar style={{ backgroundColor: topBarBgColor }} />
          <StyledNavHeader isModelDropdownVisible={isModelDropdownVisible} />
          {children}
        </ContentCard>
        <SideActionBarRight style={{ width: isRightSideOpen ? 300 : 0 }}>
          {rightContent}
        </SideActionBarRight>
      </Container>
      <MessageModal
        content={messageModalContent}
        setContent={setMessageModalContent}
      />
    </OuterContainer>
  );
};

export default LayoutNew;
