import AreaChartParams from "components/AreaChartParams";
import AreaChartParamsNew from "components/AreaChartParamsNew";
import AreaChartPercentageParams from "components/AreaChartPercentageParams";
import AreaChartPercentageParamsNew from "components/AreaChartPercentageParamsNew";
import BandedChartParams from "components/BandedChartParams";
import BarChartParams from "components/BarChartParams";
import BarChartParamsNew from "components/BarChartParamsNew";
import KeyFiguresParams from "components/KeyFiguresParams";
import LineChartParams from "components/LineChartParams";
import PieChartParams from "components/PieChartParams";
import PieChartParamsNew from "components/PieChartParamsNew";
import ScatterChartParams from "components/ScatterChartParams";
import StackedBarChartParams from "components/StackedBarChartParams";

const PLOT_TYPE_TO_COMPONENT = {
  Table: null,
  "Bar Chart": BarChartParams,
  "Bar Chart New": BarChartParamsNew,
  "Line Chart": LineChartParams,
  "Area Chart": AreaChartParams,
  "Area Chart New": AreaChartParamsNew,
  "Area Chart Percentage": AreaChartPercentageParams,
  "Area Chart Percentage New": AreaChartPercentageParamsNew,
  "Stacked Bar Chart": StackedBarChartParams,
  "Banded Chart": BandedChartParams,
  "Scatter Chart": ScatterChartParams,
  "Key Figures": KeyFiguresParams,
  "Pie Chart": PieChartParams,
  "Pie Chart New": PieChartParamsNew,
};

export const PLOT_TYPES = Object.keys(PLOT_TYPE_TO_COMPONENT);

export const getPlotComponent = (plotType = "", plotProps = {}) => {
  const PlotComponent = PLOT_TYPE_TO_COMPONENT[plotType];
  if (!PlotComponent) {
    return null;
  }

  return <PlotComponent {...plotProps} />;
};
