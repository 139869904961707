import {
  getPipelineConfig,
  getSigmasV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import { postDownloadExcelFileOfTable } from "api/backend/projectServiceEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import ColumnsListModal from "components/ColumnsListModal";
import { DocSearchIcon, ListIcon, TableGridIcon } from "components/IconsNew";
import KanbanConfigModal from "components/KanbanConfigModal";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import PipelineRecordsTable from "components/PipelineRecordsTable";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, DownloadIcon, PlusIcon } from "components/ui/Icons";
import { uniq } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromIndex, getColorFromString } from "utils/common";

const doDownloadTable = async (columns, records) => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      let val = record?.fields?.[columnName]?.value || "";
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  grid-template-rows: repeat(6, auto) 1fr;
  overflow: hidden;
  align-content: start;
  padding: 120px;
  padding-top: 80px;
  padding-bottom: 40px;
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  align-content: start;
`;

const GroupCard = styled.div`
  background-color: #eaeaea;
  padding: 10px;
  border-radius: 10px;
  height: max-content;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 250px;
  height: 100px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.14) 1px 1px 2px, rgba(0, 0, 0, 0.12) 0px 0px 2px;
  :hover {
    background-color: #e3e3e3;
  }
`;

const GroupTitle = styled.div`
  grid-column: span 2;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
  svg {
    fill: black;
    height: 18px;
  }
`;

const TopBar = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  width: 800px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ButtonWordIcon = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: max-content;
  svg {
    fill: white;
  }
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;
  border-radius: 14px;
`;

const SwitchLink = styled.div`
  color: white;
  background-color: ${props => (props.isActive ? "#d4d4d4" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  svg {
    fill: #000000;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    opacity: 1;
  }
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  svg {
    fill: black;
    height: 14px;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;

  font-weight: 600;
  z-index: 1;
`;

const IconAndText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Tr = styled.tr`
  cursor: pointer;
  :hover {
    background-color: #f3f5f7;
  }
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const KanbanColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 20px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const KanbanColumnTitle = styled.div`
  font-weight: 600;
  justify-self: start;
  padding: 10px;
  height: 36px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${props => props.bgColor};
  color: white;
  position: sticky;
  top: 0px;
`;

const KanbanColumn = styled.div`
  background-color: ${props => props.bgColor}33;
  width: 270px;
  display: grid;
  gap: 10px;
  justify-items: center;
  align-content: start;
  padding: 10px 0;
  padding-top: 0;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
`;

export const SwitchLinksBar = ({ viewType = "", setViewType = () => {} }) => (
  <SwitchLinks>
    <SwitchLink
      onClick={() => {
        setViewType("cards");
        localStorage.setItem("viewType", "cards");
      }}
      isActive={viewType === "cards"}
    >
      <TableGridIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("table");
        localStorage.setItem("viewType", "table");
      }}
      isActive={viewType === "table"}
    >
      <ListIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("kanban");
        localStorage.setItem("viewType", "kanban");
      }}
      isActive={viewType === "kanban"}
    >
      <ListIcon style={{ transform: "rotate(90deg)" }} />
    </SwitchLink>
  </SwitchLinks>
);

const AppPageRecords = () => {
  const navigate = useNavigate();

  const { pipelineConfigId } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [fields, setFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingLayout, setIsEditingLayout] = useState(false);

  const tableId = pipelineConfig?.sourceTables?.[0]?.id;
  const [viewType, setViewType] = useState(
    localStorage?.getItem("viewType") || "table"
  );

  const [dateFilter, setDateFilter] = useState("");
  const [createdByFilter, setCreatedByFilter] = useState("");
  const [query, setQuery] = useState("");

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [pipelineConfigId, tableId]);

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const doPopulateSigmaRecords = async (pipelineId, tableName) => {
    const { data } = await getSigmasV2({
      pipelineId,
      tableName,
    });
    setSigmaRecords(data || []);
    if (!selectedRecordId) {
      setSelectedRecordId(data?.[0]?.id);
    }
  };

  const doSaveMetaFields = async (metaFields = {}) => {
    const { data } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          ...metaFields,
        },
      }
    );
    setPipelineConfig(data);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}&type=RECORD`);
    }
  };

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];
  const recordNameColumn = columns?.find(column => !column?.groupName);

  let dbTableColumns = columns;
  if (pipelineConfig?.meta?.dbColumnNames) {
    dbTableColumns = columns.filter(column =>
      pipelineConfig?.meta?.dbColumnNames?.includes(column?.name)
    );
  }

  // sort by createdAt
  const sigmaRecordsMostRecentFirst = sigmaRecords
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    ?.filter(record => {
      if (dateFilter === "today") {
        return (
          new Date(record.createdAt).toDateString() ===
          new Date().toDateString()
        );
      }
      if (dateFilter === "yesterday") {
        return (
          new Date(record.createdAt).toDateString() ===
          new Date(Date.now() - 86400000).toDateString()
        );
      }
      if (dateFilter === "last-7-days") {
        return new Date(record.createdAt) > new Date(Date.now() - 7 * 86400000);
      }
      return true;
    });

  const allAuthors = uniq(
    sigmaRecordsMostRecentFirst?.map(record => record?.createdBy)
  );

  const cardsView = (
    <Cards>
      {sigmaRecordsMostRecentFirst?.map(record => {
        return (
          <GroupCard
            onClick={() =>
              navigate(`/apps/${pipelineConfigId}/records/${record?.id}`)
            }
          >
            <GroupTitle>
              <DocSearchIcon />
              {record?.fields?.[recordNameColumn?.name]?.value || record.id}
            </GroupTitle>
          </GroupCard>
        );
      })}
    </Cards>
  );

  const tableView = (
    <PipelineRecordsTable
      columns={dbTableColumns}
      pipelineConfig={pipelineConfig}
      sigmaRecords={sigmaRecordsMostRecentFirst}
    />
  );

  let kanbanView = null;
  const kanbanColumnCategories = columns?.find(
    col => col?.name === pipelineConfig?.meta?.kanbanColumnName
  )?.categories;
  if (kanbanColumnCategories?.length) {
    kanbanView = (
      <KanbanColumns>
        {kanbanColumnCategories.map((category, catIndex) => (
          <KanbanColumn bgColor={getColorFromIndex(catIndex)}>
            <KanbanColumnTitle bgColor={getColorFromIndex(catIndex)}>
              {category}
            </KanbanColumnTitle>
            {[...sigmaRecordsMostRecentFirst]
              .filter(
                record =>
                  record?.fields?.[pipelineConfig?.meta?.kanbanColumnName]
                    ?.value === category
              )
              .map(record => (
                <GroupCard
                  onClick={() =>
                    navigate(`/apps/${pipelineConfigId}/records/${record?.id}`)
                  }
                >
                  <GroupTitle>
                    <DocSearchIcon />
                    {record?.fields?.[recordNameColumn?.name]?.value ||
                      record.id}
                  </GroupTitle>
                </GroupCard>
              ))}
          </KanbanColumn>
        ))}
      </KanbanColumns>
    );
  }

  return (
    <LayoutApp>
      <Container>
        <Title
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          Your records
          <AddSigmaRecordModalNew
            trigger={
              <ButtonWordIcon isPrimary>
                <PlusIcon /> New
              </ButtonWordIcon>
            }
            pipelineConfig={pipelineConfig}
          />
        </Title>
        <Gap />
        <StyledInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Search records..."
        />
        <Gap height="20px" />

        <TopBar>
          <FilterContainer>
            <StyledSelect
              value={dateFilter}
              onChange={e => setDateFilter(e.target.value)}
            >
              <option value="">Creation date</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last-7-days">Last 7 days</option>
            </StyledSelect>
            {dateFilter !== "" && (
              <StyledCrossIcon onClick={() => setDateFilter("")} />
            )}
          </FilterContainer>
          <FilterContainer>
            <StyledSelect
              value={createdByFilter}
              onChange={e => setCreatedByFilter(e.target.value)}
            >
              <option>Created by</option>
              {allAuthors?.map(author => (
                <option value={author}>{author}</option>
              ))}
            </StyledSelect>
            {createdByFilter !== "" && (
              <StyledCrossIcon onClick={() => setCreatedByFilter("")} />
            )}
          </FilterContainer>
          <div />
          <DownloadIcon
            style={{ cursor: "pointer" }}
            onClick={() => doDownloadTable(dbTableColumns, sigmaRecords)}
          />
          {viewType === "cards" && <div />}
          {viewType === "table" && (
            <ColumnsListModal
              value={
                pipelineConfig?.meta?.dbColumnNames || columns?.map(c => c.name)
              }
              options={columns?.map(column => ({
                value: column?.name,
                label: column?.name?.replaceAll("_", " / "),
              }))}
              onPressSave={newColumnNames =>
                doSaveMetaFields({ dbColumnNames: newColumnNames })
              }
            />
          )}
          {viewType === "kanban" && (
            <KanbanConfigModal
              value={pipelineConfig?.meta?.kanbanColumnName}
              columns={columns}
              onPressSave={newName =>
                doSaveMetaFields({ kanbanColumnName: newName })
              }
            />
          )}
          <SwitchLinksBar setViewType={setViewType} viewType={viewType} />
        </TopBar>
        <Gap height="10px" />
        {viewType === "cards" && cardsView}
        {viewType === "table" && tableView}
        {viewType === "kanban" && kanbanView}
      </Container>
    </LayoutApp>
  );
};

export default AppPageRecords;
